import VehicleService from "@/services/vehicle.service";

export const vehicle = {
	namespaced: true,
	state: {
		orders: [],
		vehicles: [],
		vehiclesSearch: { input: '', companyID: 0 },

		statuses: ['New', 'Approved'],

		warrantyClaims: [],
		warrantyClaimSearch: { input: '', companyID: 0, from: '', to: '', statuses: ['New', 'Returned', 'Approved', 'Submitted'] },

		maintenances: [],
		maintenanceSearch: { input: '', companyID: 0, from: '', to: '', statuses: ['New'] },

		serviceReports: [],
		serviceReportSearch: { input: '', companyID: 0, from: '', to: '', statuses: ['New', 'Returned', 'Submitted'] },

		serviceReminders: [],
		serviceReminderSearch: { input: '', companyID: 0 },

	},
	actions: {
		searchOrders({ commit }, data) {
			return VehicleService.searchOrders(data).then(
				data => {
					commit('searchOrders_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('searchOrders_ERR');
					return Promise.reject(error);
				}
			);
		},
		updateOrder({ commit }, data) {
			commit('updateOrder_OK', data);
		},
		deleteOrder({ commit }, data) {
			commit('deleteOrder_OK', data);
		},
		searchVehicles({ commit }, data) {
			commit('searchVehicles_INPUT', data);
			return VehicleService.searchVehicles(data).then(
				data => {
					commit('searchVehicles_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('searchVehicles_ERR');
					return Promise.reject(error);
				}
			);
		},
		updateSearchView({ commit }, data) {
			commit('searchVehicles_INPUT', data);
		},
		updateVehicle({ commit }, data) {
			commit('updateVehicle_OK', data);
		},
		removeVehicle({ commit }, data) {
			commit('removeVehicle_OK', data);
		},
		updateVehicles({ commit }, data) {
			commit('updateVehicles_OK', data);
		},
		updateStatuses({ commit }, data) {
			commit('updateStatuses_OK', data);
		},

		//WarrantyClaims
		searchWarrantyClaims({ commit }, data) {
			commit('searchWarrantyClaims_INPUT', data);
			return VehicleService.searchWarrantyClaims(data).then(
				data => {
					commit('searchWarrantyClaims_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('searchWarrantyClaims_ERR');
					return Promise.reject(error);
				}
			);
		},
		updateWarrantyClaim({ commit }, data) {
			commit('updateWarrantyClaim_OK', data);
		},
		deleteWarrantyClaim({ commit }, data) {
			commit('deleteWarrantyClaim_OK', data);
		},

		//Maintenances
		searchMaintenances({ commit }, data) {
			commit('searchMaintenances_INPUT', data);
			return VehicleService.searchMaintenances(data).then(
				data => {
					commit('searchMaintenances_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('searchMaintenances_ERR');
					return Promise.reject(error);
				}
			);
		},
		updateMaintenance({ commit }, data) {
			commit('updateMaintenance_OK', data);
		},
		deleteMaintenance({ commit }, data) {
			commit('deleteMaintenance_OK', data);
		},

		//ServiceReports
		searchServiceReports({ commit }, data) {
			commit('searchServiceReports_INPUT', data);
			return VehicleService.searchServiceReports(data).then(
				data => {
					commit('searchServiceReports_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('searchServiceReports_ERR');
					return Promise.reject(error);
				}
			);
		},
		updateServiceReport({ commit }, data) {
			commit('updateServiceReport_OK', data);
		},
		deleteServiceReport({ commit }, data) {
			commit('deleteServiceReport_OK', data);
		},

		//ServiceReminders
		
		searchServiceReminders({ commit }, data) {
			commit('searchServiceReminders_INPUT', data);
			return VehicleService.searchServiceReminders(data).then(
				data => {
					commit('searchServiceReminders_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('searchServiceReminders_ERR');
					return Promise.reject(error);
				}
			);
		},
		
	},
	mutations: {
		searchOrders_OK(state, orders) {
			state.orders = orders;
		},
		searchOrders_ERR(state) {
			state.orders = [];
		},
		updateOrder_OK: (state, item) => {
			const existsAtIndex = state.orders.findIndex(u => u.vehicleOrderID === item.vehicleOrderID);
			if (existsAtIndex !== -1) {
				state.orders[existsAtIndex] = item;
			} else {
				state.orders.unshift(item);
			}
			state.orders = [...state.orders];
		},
		deleteOrder_OK: (state, item) => {
			state.orders = state.orders.filter(u => u.vehicleOrderID !== item.vehicleOrderID);
			state.orders = [...state.orders];
		},
		searchVehicles_OK(state, vehicles) {
			state.vehicles = vehicles;
		},
		searchVehicles_ERR(state) {
			state.vehicles = [];
		},
		updateVehicles_OK(state, vehicles) {
			//state.vehicles = vehicles;
			vehicles.forEach(function (item) {
				var existsAtIndex = state.vehicles.findIndex(u => u.vehicleID === item.vehicleID);
				if (existsAtIndex !== -1) {
					state.vehicles[existsAtIndex] = item;
				} else {
					state.vehicles.unshift(item);
				}
			});
			state.vehicles = [...state.vehicles];
			state.serviceReminders = [];
			state.serviceReminderSearch = {};
		},
		updateVehicle_OK: (state, item) => {
			const existsAtIndex = state.vehicles.findIndex(u => u.vehicleID === item.vehicleID);
			if (existsAtIndex !== -1) {
				state.vehicles[existsAtIndex] = item;
			} else {
				state.vehicles.unshift(item);
			}
			state.vehicles = [...state.vehicles];
			state.serviceReminders = [];
			state.serviceReminderSearch = {};
		},
		removeVehicle_OK: (state, item) => {
			state.vehicles = state.vehicles.filter(u => u.vehicleID !== item.vehicleID);
			state.vehicles = [...state.vehicles];
			state.serviceReminders = [];
			state.serviceReminderSearch = {};
		},
		searchVehicles_INPUT(state, input) {
			state.vehiclesSearch = input;
		},
		updateStatuses_OK(state, statuses) {
			state.statuses = statuses;
		},

		searchWarrantyClaims_OK(state, vehicles) {
			state.warrantyClaims = vehicles;
		},
		searchWarrantyClaims_ERR(state) {
			state.warrantyClaims = [];
		},
		searchWarrantyClaims_INPUT(state, input) {
			state.warrantyClaimSearch = input;
		},
		updateWarrantyClaim_OK: (state, item) => {
			delete item.serviceRows;
			delete item.serviceFiles;
			delete item.repairFiles;
			delete item.productFiles;
			delete item.expenseFiles;
			delete item.vehicleWarranties;
			delete item.historyRows;
			const existsAtIndex = state.warrantyClaims.findIndex(u => u.vServiceID === item.vServiceID);
			if (existsAtIndex !== -1) {
				state.warrantyClaims[existsAtIndex] = item;
			} else {
				state.warrantyClaims.unshift(item);
			}
			state.warrantyClaims = [...state.warrantyClaims];
			state.serviceReminders = [];
			state.serviceReminderSearch = {};
		},
		deleteWarrantyClaim_OK: (state, item) => {
			state.warrantyClaims = state.warrantyClaims.filter(u => u.vServiceID !== item.vServiceID);
			state.warrantyClaims = [...state.warrantyClaims];
			state.serviceReminders = [];
			state.serviceReminderSearch = {};
		},


		searchMaintenances_OK(state, vehicles) {
			state.maintenances = vehicles;
		},
		searchMaintenances_ERR(state) {
			state.maintenances = [];
		},
		searchMaintenances_INPUT(state, input) {
			state.maintenanceSearch = input;
		},
		updateMaintenance_OK: (state, item) => {
			delete item.serviceRows;
			delete item.serviceFiles;
			delete item.expenseFiles;
			//delete item.vehicleWarranties;
			const existsAtIndex = state.maintenances.findIndex(u => u.vServiceID === item.vServiceID);
			if (existsAtIndex !== -1) {
				state.maintenances[existsAtIndex] = item;
			} else {
				state.maintenances.unshift(item);
			}
			state.maintenances = [...state.maintenances];
			state.serviceReminders = [];
			state.serviceReminderSearch = {};
		},
		deleteMaintenance_OK: (state, item) => {
			state.maintenances = state.maintenances.filter(u => u.vServiceID !== item.vServiceID);
			state.maintenances = [...state.maintenances];
			state.serviceReminders = [];
			state.serviceReminderSearch = {};
		},


		searchServiceReports_OK(state, vehicles) {
			state.serviceReports = vehicles;
		},
		searchServiceReports_ERR(state) {
			state.serviceReports = [];
		},
		searchServiceReports_INPUT(state, input) {
			state.serviceReportSearch = input;
		},
		updateServiceReport_OK: (state, item) => {
			delete item.serviceRows;
			delete item.serviceFiles;
			delete item.expenseFiles;
			//delete item.vehicleWarranties;
			const existsAtIndex = state.serviceReports.findIndex(u => u.vServiceID === item.vServiceID);
			if (existsAtIndex !== -1) {
				state.serviceReports[existsAtIndex] = item;
			} else {
				state.serviceReports.unshift(item);
			}
			state.serviceReports = [...state.serviceReports];
		},
		deleteServiceReport_OK: (state, item) => {
			state.serviceReports = state.serviceReports.filter(u => u.vServiceID !== item.vServiceID);
			state.serviceReports = [...state.serviceReports];
		},

		searchServiceReminders_OK(state, reminders) {
			state.serviceReminders = reminders;
		},
		searchServiceReminders_ERR(state) {
			state.serviceReminders = [];
		},
		searchServiceReminders_INPUT(state, input) {
			state.serviceReminderSearch = input;
		},
		
	}
};
